import { handleUpdate, setListenContact } from '@/redux/slices/crm';
import { Alert, Snackbar } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSocketContext } from './SocketContext';

interface SocketHandlerProps extends PropsWithChildren {
    listenContact?: string,
    listenAgent?: string
}

export default function SocketHandler({
    children,
    listenAgent,
    listenContact
}: SocketHandlerProps) {
    const [lastListenAgent, setLastListenAgent] = useState<string | undefined>(listenAgent);
    const [lastListenContact, setLastListenContact] = useState<string | undefined>(listenContact);

    const { joinRoom, leaveRoom, status, addReceiveEventFn, removeReceiveEventFn } = useSocketContext();
    const dispatch = useDispatch();

    useEffect(() => {
        const fnKey = 'crmHandleFn';

        addReceiveEventFn(fnKey, (message) => {
            if (message.eventType == 'crmEvent') {
                dispatch(handleUpdate(message.payload));
            }
        });

        return () => {
            removeReceiveEventFn(fnKey)
        }
    }, [])

    useEffect(() => {
        dispatch(setListenContact(listenContact))
    }, [listenContact])

    useEffect(() => {
        const manageRoom = (
            currentId: string | undefined,
            newId: string | undefined,
            getRoomName: (id: string) => string
        ) => {
            if (currentId && currentId !== newId) {
                console.log('Leave room...', getRoomName(currentId));
                leaveRoom(getRoomName(currentId));
            }

            if (newId) {
                console.log('Connecting to room...', getRoomName(newId));
                joinRoom(getRoomName(newId));
            }
        };

        const getContactRoomName = (id: string) => `private-listen-contact-${id}`;
        const getAgentRoomName = (id: string) => `private-listen-agent-${id}`;

        manageRoom(lastListenContact, listenContact, getContactRoomName);
        manageRoom(lastListenAgent, listenAgent, getAgentRoomName);

        setLastListenContact(listenContact);
        setLastListenAgent(listenAgent);

        return () => {
            if (lastListenContact) leaveRoom(getContactRoomName(lastListenContact));
            if (lastListenAgent) leaveRoom(getAgentRoomName(lastListenAgent));
        };
    }, [listenAgent, listenContact, lastListenAgent, lastListenContact]);

    return <>
        {children}
        <Snackbar open={status !== 'online'}>
            <Alert severity="error">Volviendo a conectar...</Alert>
        </Snackbar>
    </>
}

